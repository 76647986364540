@tailwind base;
@tailwind components;
@tailwind utilities;

.customScrollbar {

}

.customScrollbar::-webkit-scrollbar {
    width: 16px;
}

/* background of the scrollbar except button or resizer */
.customScrollbar::-webkit-scrollbar-track {

}

/* scrollbar itself */
.customScrollbar::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.customScrollbar::-webkit-scrollbar-button {
    display: none;
}
